.accordion-item {
	border-bottom: 1px solid #033044;
}

.accordion-button {
	color: white;
	border: none;
	background-color: inherit;
	font-size: large;
}

.chevron-btn::after {
	font-family: "Font Awesome 5 Free";
	content: "\f078";
	display: inline-block;
	margin-left: 10px;
	vertical-align: middle;
	font-weight: 900;
	font-size: 1rem;
	opacity: 0.5;
}

.chevron-btn:not(.collapsed):after {
	content: "\f077";
}

.input-check {
	margin-right: 7px;
}

.alert {
	width: 50%;
	background-color: #033044;
	float: right;
	z-index: 2;
	border: 1px #033044 solid;
}

.alert.alert-dark.show {
	background-color: #033044;
	border: 1px solid #065c81;
}

.close-btn {
	cursor: pointer;
}