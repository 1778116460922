@media screen and (min-width: 1280px) {
  #Container {
    max-width: 1480px;
  }
}

@media screen and (max-width: 1280px) {
  #Container {
    max-width: 1662px;
  }
}

/* (1920x1080) Full HD Display */
@media screen and (max-width: 1920px) {
  #Container {
    max-width: 1580px;
  }
}

/* (1600x900) HD+ Display */
@media screen and (max-width: 1600px) {
  #Container {
    max-width: 1280px;
    min-width: 1100px;
  }
}
